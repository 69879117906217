@import '../../assets/styles/variables.scss';

.checkbox-key-wrapper{
	display: inline-block;
	
	.checkbox-input-container{

		label{
			font-family: $arialList;
			font-size: 12px;
			color: $dark;
			letter-spacing: 0;
			margin-bottom: 0px;

			.checkbox{
				display: inline-block;
				width: 14px;
				height: 14px;
				border: 1px solid $input-label;
				border-radius: 2px;
				position: relative;
				top: 3px;
				background: $white;

				&.checked{
					background: $input-label;
				}

				img{
					position: absolute;
					top: 2px;
				}
			}


			input{
				height: 18px;
				width: 18px;
				left: -2px;
				top: 1px;
				-webkit-appearance: none;
				-moz-appearance: none;
				-o-appearance: none;
				appearance: none;
				margin-top: 0px;
				margin-left: 0px;

				&:focus{
					outline: 0;
					box-shadow: 0 0 0 0.2rem rgba(139, 29, 65, 0.25);
				}
			}
		}
	}
}
