@import "../../assets/styles/variables.scss";

.context-menu{
	position: relative;
	right: -5px;

	.context-button{
		background-color: transparent;
		border: none;
		border-radius: 0.25rem !important;
		padding: 3px;
		line-height: 16px;
	
		&:hover, &:active{
			background-color: transparent !important;
		}
	}

	.dropdown-menu{
		padding: 0px;
		border-radius: 1px;
		-webkit-box-shadow: 0 3px 3px 0 rgba(0,0,0,.1);
		box-shadow: 0 3px 3px 0 rgba(0,0,0,.1);
		min-width: 119px;

		.dropdown-item{
			font-size: 12px;
			font-family: $font-family-sans-serif !important;
			font-weight: normal;
			padding-left: 10px;
			padding-right: 10px;
			padding-top: 8px;
			padding-bottom: 8px;
			line-height: 14px;

			&:hover{
				background-color: $border-medium-gray;
				color: $text-color;
			}
		}

		.close-menu-item {
			color: $text-light-gray;
			border-top: 1px solid $border-medium-gray;
		}

		button, a {
			border-radius: 0;
			text-decoration: none !important;
		}
	}
}
