@import "../../assets/styles/variables.scss";

.country-toggle{
	.btn{
		border: 1px solid $bg-dark-gray !important;
		background-color: $white;
		color: $button-gray;
		padding-top: 2px;
		padding-bottom: 2px;
		padding-left: 0px;
		padding-right: 0px;
		line-height: 12px;
		
		span{
			font-size: 12px;
		}

		.flag-icon, .check{
			margin-right: 5px;
			position: relative;
			top: 0px;
		}

		.flag-icon{
			top: -1px;
		}

		&.active{
			background-color: $bg-dark-gray !important;
			color: $white;
		}
	}
}