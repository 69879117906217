@import 'assets/styles/variables.scss';

.quintile-chart{
	display: inline-block;

	.value{
		font-family: $arialList;
		font-size: 12px;
		//line-height: 12px;
		color: #383B3E;
		letter-spacing: 0;
		text-align: right;
		margin-bottom: 1px;
	}

	.cell-container{
		height: 10px;

		.cell{
			display: inline-block;
			vertical-align: top;
			width: 10px;
			height: 10px;
			border-top: 1px solid #BBBCBC;
			border-left: 1px solid #BBBCBC;
			border-bottom: 1px solid #BBBCBC;

			&:last-child{
				border-right: 1px solid #BBBCBC;
			}
			
			&.active{
				background: #8B1D41;
				border-top: 1px solid #8B1D41;
				border-left: 1px solid #8B1D41;
				border-bottom: 1px solid #8B1D41;

				&:last-child{
					border-right: 1px solid #8B1D41;
				}
			}
		}
	}
}