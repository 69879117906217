
@import "./variables.scss";

// component & shared inports
@import "components/Loader/Loader.scss";
@import "components/NewsTeaser/NewsTeaser.scss";
@import "components/Pagination/Pagination.scss";
@import "components/NewsList/NewsList.scss";
@import "components/ModuleContainer/ModuleContainer.scss";
@import "components/AsOfDateFooter/AsOfDateFooter.scss";
@import "components/BasicTable/BasicTable.scss";
@import "components/WatchlistTable/WatchlistTable.scss";
@import "components/Icon/Icon.scss";
@import "components/Checkbox/Checkbox.scss";
@import "components/QuoteCardList/QuoteCardList.scss";
@import "components/ContextMenu/ContextMenu.scss";
@import "components/SymbolCompany/SymbolCompany.scss";
@import "components/CountryToggle/CountryToggle.scss";
@import "components/SelectDropdown/SelectDropdown.scss";
@import "components/QuintileChart/QuintileChart.scss";

@import "Views/Shared/TradeLink/TradeLink.scss";
@import "Views/Shared/PopupChart/PopupChart.scss";



@font-face {
	font-family: 'Whitney-Medium';
	src: url('../media/Whitney-Medium.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Whitney-Semibold';
	src: url('../media/Whitney-Semibold.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Arial-BoldMT';
	src: url('../media/ARIALBOLDMT.OTF') format('opentype');
	font-weight: normal;
	font-style: normal;
}

#root, #rsg-root, .modal{
	font-family: $arialList;
	color: $gray-dark;

	*:focus {
		box-shadow: none !important;
		outline: 2px solid #616366 !important;
		outline-offset: 1px !important;
		border-color: initial !important;
	}

	.row{
		margin-left: inherit;
		margin-right: inherit;
		&.marg-5{
			margin-top: 5px;
		}
		&.marg-10{
			margin-top: 10px;
		}
	}

	a{
		color: $gray-dark;
		font-family: $arialList;
		text-decoration: underline;

		&:hover{
			color: $link-hover;
		}

		&.btn{
			&.btn-outline-primary{
				color: $primary;

				&:hover{
					color: $white;
				}
			}
		}
	}

	.red{
		color: $negative;
	}

	.green{
		color: $positive;
	}

	h1{
		font-family: $whitneyMediumList;
	}

	h3, .h5{
		font-family: $whitneySemiboldList;
	}

	.btn{

		&.btn-secondary, &.btn-primary, &.btn-outline-primary, &.btn-outline-secondary{
			min-width: 70px;
			font-family: $whitneyMediumList;
			font-size: 14px;

			&.disabled{
				color: #bbbcbc;
				background-color: #f2f3f2;
				border-color: #f2f3f2;
			}

			&.btn-lg{
				min-width: 80px;
				font-size: 16px;
			}
		}
	}

	.table{
		.btn{
			min-width: 15px;
		}
	}

	.link-button{
		color: $dark;
		font-family: $whitneySemiboldList;
		text-align: left;
		padding: 0px;
		margin: 0px;
		line-height: 0px;
		border: none;
		background: transparent;

		&:hover{
			text-decoration: underline;
		}
	}

	.disclaimer {
		color: $muted-date;
		font-family: $font-family-sans-serif;
		font-size: 12px;
	}

	.text-upper{
		text-transform: capitalize;
	}

	figure{
		margin: 0px;
	}

	.hide{
		display: none !important;
	}

	.alert{
		border-radius: 3px;
		margin: 0px;
		padding: 5px;

		&.alert-danger{
			background: $danger;
			border: none;
		}
	}
}

/*
The modal is added to the dom outside the #root
*/
// .modal {
// 	.btn{
// 		min-width: 70px;
// 	}

// 	.red{
// 		color: $red;
// 	}

// 	.green{
// 		color: $green;
// 	}

// 	.hide{
// 		display: none !important;
// 	}

// 	.alert{
// 		border-radius: 3px;
// 		margin: 0px;
// 		padding: 5px;

// 		&.alert-danger{
// 			background: $danger;
// 			border: none;
// 		}
// 	}
// }

/*
	Global style to disable the flex row wrap that safari has a hard time dealing with
*/
.safari-fix{

	.row, .nav {
		flex-wrap: nowrap !important;
		display: -webkit-box;


		&:before{
			display: none;
		}
	}
}
