.loader-wrapper{
	position: absolute;
	width: 100%;
	height: 100%;
	min-width: 31px;
	min-height: 31px;
	background: #fff;
	opacity: .5;
	z-index: 100;

	.loader-icon{
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}