@import "assets/styles/variables.scss";

.news-item-teaser-wrapper {
	padding-top: 7px;
	padding-left: 15px;
	border-bottom: 1px solid #cccccc;
	
	&:first-child{
		border-top: 1px solid #cccccc;
	}

	.news-headline{
		font-family: $whitneySemiboldList;
		font-size: 12px;
		color: $text-color;
		letter-spacing: 0;
		line-height: 14px;
		margin-bottom: 0px;	
	}

	.author {
		margin: 0 0 5px 0;
		line-height: 12px;
		color: $muted-label;
	}

	.news-item-info {
		color: $muted-label;
		font-size: 12px;
		margin-bottom: 5px;

		&.etf-commentary {
			margin-bottom: 0;
		}

		.mentioned{
			margin-left: 5px;

			a{
				margin-right: 3px;

				&:first-child{
					margin-left: 5px;
				}

				&:after{
					content: ','
				}
				
				&:last-child{
					&:after{
						content: ''
					}
				}
			}
		}
	}

	.news-item-readmore {
		font-size: 12px;
	}

	.news-item-readmore:hover {
		color: $link-hover;
	}
	
	.news-item-teaser{
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0;
		margin-bottom: 5px;
	}

	.news-item-teaser p {
		margin-bottom: 0 !important;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0;
	}
	
	button {
		font-size: 12px;
		padding-top:0px;
		padding-bottom: 3px;
		padding-left: 0px;
		padding-right: 0px;
		background: transparent;
		border: none;
		text-decoration: underline;
	}

}

