@import "assets/styles/variables.scss";

.error-boundry-wrapper {
	margin: 10px;
	padding: 10px;
	background-color: #faf1f3;
	border-radius: 4px;
	color: $gray-dark;

	span {
		display: table-cell;
	}

	.error-boundry-icon {
		padding-right: 10px;

		img {
			height: 20px;
			width: 20px;
		}
	}

	.error-boundry-header, .error-boundry-body {
		font-size: 13px;
		line-height: 17px;
	}

	.error-boundry-header {
		font-weight: 700;
		margin-bottom: 10px;
	}
}
