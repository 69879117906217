@import "assets/styles/variables.scss";

.advanced-chart-button{
	color: $dark;
	font-family: $arialList;
	text-align: left;
	padding: 0px;
	margin: 0px;
	border: none;
	background: transparent;
	text-decoration: underline;
	
	&:hover{
		text-decoration: underline !important;
		color: $link-hover !important;
	}
}


.dropdown-menu{
	.advanced-chart-button{
		text-decoration: none;
	}
}