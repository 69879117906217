@import "../../assets/styles/variables.scss";

.as-of-date-footer{
	color: $muted-date;
	font-size: 12px;
	line-height: 12px;

	.date{

	}

	.delayed-disclaimer{

	}
}

.as-of-date-mt {
	margin-top: 5px;
}
.as-of-date-ml {
	margin-left: 5px;
}