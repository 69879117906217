@import "../../assets/styles/variables.scss";

.select-dropdown{
	display: inline-block;

	.select-dropdown-button{
		background-color: $white;
		border-radius: 0px !important;
		border: 1px solid $input-border;
		color: $select-dropdown;
		padding-top: 4px;
		padding-bottom: 4px;
		padding-left: 10px;
		padding-right: 34px;
		height: 30px;
		line-height: 13px;
		font-size: 12px !important;
		-webkit-appearance: none;
		-moz-appearance: none;
		position: relative;

		&.form-control:disabled{
			// background-color: #e9ecef;
			opacity: .6;
		}

		&::-ms-expand {
			display: none;
		}

		&.watch-list {
			width: 100%;
			display: flex;
			justify-content: space-between;
		}

		&:hover, &:active{
			color: $select-dropdown !important;
		}

		.selected-label{
			font-size: 12px;
			font-family: $arialList;
			margin-right: 20px;
			vertical-align: middle;

			&.watch-list {
				align-self: flex-end;
			}
		}

		.watch-list {
			align-self: center;
		}


		&:hover, &:active{
			background-color: $white !important;
		}

	}

	.input-wrapper{
		position: relative;
		display: flex;

		.menu-closed, .menu-open{
			pointer-events: none;
			position: absolute;
			top: calc(50% - 3px) !important;
			right: 10px;

			&.disabled{
				opacity: .6;
			}
		}

		select{

			&.limit{
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			option{
				padding-right: 5px;
			}
		}
	}

	label{
		font-family: $arialList;
		font-size: 12px;
		color: $select-dropdown;
		letter-spacing: 0;
		line-height: 12px;
		margin-bottom: 0px;
		font-weight: bold;
		display: block;

		&.label-pos-left {
			float: left;
			margin-right: 5px;
			margin-top: 7px;
		}

		&.label-pos-right {
			float: right;
			margin-left: 5px;
			margin-top: 7px;
		}
	}
}
