
$primary-blue-1: #0F2D5D !default;
$primary-blue-2: #073586 !default;
$primary-blue-3: #41B6E6 !default;

$primary-teal: #AF0B1C !default;
$accent-orange: #F28400 !default;
$accent-green: #006747 !default;
$red: #d52222 !default;
$green: #168511 !default;

//## Gray and brand colors for use across Bootstrap.
$black:                  #000000 !default;
$gray-dark:              #383b3e !default;
$gray:                   #A5A5A5 !default;
$gray-light:             #EFEFEF !default;
$white:                  #FFFFFF !default;
$gray-300:               #dddddd !default;

//## Gray and brand colors for use across Bootstrap.
$brand-danger:          $red !default;

$primary:          #8b1d41 !default;
$primary-light:    $primary-blue-2 !default;
$primary-xlight:   $primary-blue-3 !default;

$secondary:        $primary-teal !default;
$secondary-light:  $primary-teal !default;
$secondary-xlight: $primary-teal !default;

$success:       $accent-green !default;
$info:          $accent-orange !default;
$warning:       $accent-orange !default;
$danger:        #FBF1F3 !default;
$light:         $white !default;
$dark:          $gray-dark !default;

$theme-colors: () !default;
$theme-colors: map-merge((
  "primary":    $primary,
  "primary-light": $primary-light,
  "primary-xlight": $primary-xlight,
  "secondary":  $secondary,
  "secondary-light":  $secondary-light,
  "secondary-xlight":  $secondary-xlight,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
), $theme-colors);


// Chart Colors
$chart-1: #0065AA !default;
$chart-2: #1B7D5A !default;
$chart-3: #B73917 !default;
$chart-4: #333479 !default;
$chart-5: #8A1C41 !default;
$chart-6: #995002 !default;
$chart-7: #024423 !default;
$chart-8: #D03E5E !default;
$chart-9: #7B6E74 !default;
$chart-10: #101316 !default;

//== Colors
//
$navy-blue: #0065AA !default;
$medium-gray:           #D8D8D8 !default;
$text-black:            #000 !default;
$dark-label: #333333 !default;
$timestamp-gray:        #666 !default;
$text-light-gray:       #767676 !default;
$text-dark-gray: #666666 !default;
$button-gray: #444444 !default;
$bg-light-gray: #f2f3f2 !default;
$border-light-gray: #f2f3f2 !default;
$border-medium-gray: $medium-gray !default;
$border-table: #dddddd !default;
$bg-dark-gray: #666666 !default;
$sort-gray: #E0E0DF !default;
$heading-1: #606366 !default;
$select-dropdown: #383B3E !default;
$chart-button: #383B3E !default;
$table-data: #383B3E !default;
$dropdown-border: #D4D4D4 !default;
$module-border: #D4D4D4 !default;
$stylebox-background: #D4D4D4 !default;
$accordion-background: #D4D4D4 !default;
$stylebox-blue-background: #0767a8 !default;
$muted-date: #606366 !default;
$muted-label: #606366 !default;
$muted-table: #606366 !default;
$positive: #0D8008 !default;
$negative: #D41A1A !default;
$input-border: #BBBCBC !default;
$input-label: #606366 !default;
$popover-content: #606366 !default;
$table-header: #606366 !default;
$symbol-quote: #1A0000 !default;
$search-hover: #383B3E !default;
$link-hover: #C41F3E;
$search-name: #606366;
$focus-outline: #606366;
$error: #CB2247;
$overview-background: #F2F3F2;
//== Scaffolding
//

//** Background color for `<body>`.
$body-bg:               $white !default;
//** Global text color on `<body>`.
$text-color:            $gray-dark !default;
$link-hover-decoration: underline;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  ArialMT, Arial, Helvetica, sans-serif !default;
$font-family-serif:       "Times New Roman", Times, Georgia, serif !default;
$whitneyMediumList: Whitney-Medium, ArialMT, Arial, Helvetica, sans-serif !default;
$whitneySemiboldList: Whitney-Semibold, ArialMT, Arial, Helvetica, sans-serif !default;
$arialList: ArialMT, Arial, Helvetica, sans-serif !default;
$arialBoldList:  Arial-BoldMT, Arial, Helvetica, sans-serif !default;
