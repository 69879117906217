@import "../../assets/styles/variables.scss";

.watchlist-table.basic-table{
	&.table{

		thead{
			th{
				line-height: 15px;
				padding: 3px 5px;

				&:first-child{
					padding-left: 15px;
				}

				&.right-border{
					border-right: 1px solid $border-table;
				}
				.two-row-header{
					display: inline-block;
				}

				&.sortable{
					cursor: pointer;
					white-space: nowrap;

					button{
						text-decoration: underline;
						background: transparent;
						border: none;
						font: inherit;
						color: inherit;

						.two-row-header{
							text-decoration: underline;
						}

						.sort-icon{
							margin-left: 5px;
						}
					}
				}

				&:last-child{
					max-width: 40px;
					padding-left: 0px;
					padding-right: 15px;
				}
			}
		}
		tbody{
			tr{
				th,td{
					padding: 3px 5px;

					.checkbox-input-container {
						padding-right: 15px;
					}

					&:first-child{
						padding-left: 15px;
					}


					&.right-border{
						border-right: 1px solid $border-table;
					}

					&.menu-cell{
						.popover-wrapper{
							right: 0px;
							z-index: 2;
						}

						.dropdown-menu{
							z-index: 1;
						}
					}

					&.edit-cell{
						position: relative;
						.popover-wrapper{
							top: -50%;
						}
					}

					.data-unavailable{
						font-weight: 400;
						margin-left: 15px;
					}

					&:last-child{
						max-width: 40px;
						padding-left: 0px;
						padding-right: 10px;
					}
				}
			}
			.no-footer {
				th, td {
					border-bottom: 1px solid $border-table;
				}
			}
			.timestamp {
				color: $muted-date;
				font-size: 12px;
				white-space: nowrap;
			}
		}
	}
}

.watchlist-table-heading{
	margin-left: 15px;
	margin-top: 15px;
	margin-bottom: 0px;
	caption-side: top;
	color: $gray-dark;
	font-family: $whitneySemiboldList;
	font-size: 16px;
	line-height: 22px;
	padding-bottom: 8px;
}
