@import "assets/styles/variables.scss";
.pagination-wrapper {
	display: flex;
	justify-content: center;
	font-size: 12px;
	margin-top: 5px;
	color: $muted-label;
	
	.pagination{
		&.has-dropdown{
			line-height: 30px;
		}
	}

	img {
	}

	button {
		padding: 0 2px;
		background: transparent;
		border: none;
	}

	.pagination-btn-prev {
		margin-right: 6px;
	}

	.pagination-btn-next {
		margin-left: 6px;
	}

	.results-dropdown-label{

		.label-inner{
			padding-left: 10px;
			padding-right: 10px;
			border-left: 1px solid $border-table;
			display: inline-block;
			height: 14px;
			line-height: 14px;
		}
	}

	.select-dropdown{
		margin-right: 10px;
	}
}
