@import 'assets/styles/variables.scss';

.overview-quote-card-list {
	.quote-card {
		display: block;
		text-align: left;
		width: 100%;
		background-color: $bg-light-gray;
		border-left: 3px solid transparent;
		border-top: none;
		border-right: none;
		border-bottom: none;
		padding: 5px 15px 4px 11px;
		margin-bottom: 2px;
		line-height: 15px;
		appearance: none !important;
		-webkit-appearance: none !important;
		-moz-appearance: none !important;

		&:last-child{
			margin-bottom: 0px;
		}

		.symbol{
			font-family: $whitneySemiboldList;
			padding-bottom: 2px;
			text-decoration: underline;
		}

		.symbol:hover {
			color: $link-hover;
		}
		&.active {
			.symbol{
				text-decoration: none;
				color: $gray-dark;
			}
			background-color: #fff;
			border-left: 3px solid $primary;
		}

		table {
			width: 100%;

			th {
				font-size: 12px;
				font-weight: 400;
				color: $muted-table;
			}
			td{
				color: $table-data;

				&.green {
					color: $positive;
				}

				&.red {
					color: $negative;
				}
				&:not(:first-of-type){
					padding-left: 10px;
				}
			}
		}

		button {
			background-color: transparent;
			border: none;
			color: $gray-dark;
			font-family: $whitneySemiboldList;
			padding: 0;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
