@import 'assets/styles/variables.scss';

.module-wrapper{
	padding-top: 10px;
	padding-bottom: 10px;

	.module-header{
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 0px;
		
		&.with-border{
			padding-bottom: 2px;

			.header-content{
				border-bottom: 1px solid $border-medium-gray;
			}
		}

		.header-content{
			padding-left: 15px;
			padding-right: 10px;

			.title{
				font-size: 16px;
				font-family: $whitneySemiboldList;
			}

			.header-link{
				a{
					color: #363636;
				}
			}
		}
	}

	.module-footer{
		border-top: 1px solid $border-medium-gray;
		padding-left: 15px;
		padding-right: 10px;
		padding-top: 5px;
		margin-top: 5px;
	}
}