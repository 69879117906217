@import '../../assets/styles/variables.scss';

.symbol-company-cell{

	.symbol{

	}
	.exchange{
		margin-left: 5px;
		font-family: $whitneySemiboldList;
		font-size: 12px;
		color: #383B3E;
		letter-spacing: 0;
		text-decoration: none;
	}

	.company-name{
		font-family: $arialList;
		font-size: 12px;
		color: $muted-date;
		letter-spacing: 0;
		font-weight: normal;
		text-decoration: none;
	}
}
