@import "assets/styles/variables.scss";

.basic-table{
	&.table{
		margin-top: 0px;
		margin-bottom: 0px;

		caption{
			caption-side: top;
			color: $gray-dark;
			font-size: 12px;
			font-family: $arialBoldList;
			padding: 5px 15px;

			h3.large{
				font-family: $whitneySemiboldList;
				font-size: 16px !important;
				color: $text-color;
				line-height: 22px;
				margin-bottom: 3px;
			}
		}

		thead{

			.borders {
				border-right: 1px solid $border-table;
			}

			th,
			td {
				background-color: $bg-light-gray;
				color: $table-header;
				font-size: 12px;
				padding: 3px 15px 3px 15px;
				line-height: 16px;
				letter-spacing: 0;
				border: none;
				text-align: right;
				border-bottom: transparent;
				font-weight: normal;

				button{
					padding: 0px;
					text-align: right;
				}
	
				&:first-child{
					text-align: left;
					button{
						text-align: left;
					}	
				}
			}
		}

		&.options-chain-table{
			thead{
				th{
					border-bottom: 1px solid $border-table;
				}
			}		
		}

		tbody{
			tr{
				&:first-child{
					td,th{
						border-top: transparent;
					}
				}
	
				td,th{
					text-align: right;
					color: $gray-dark;
					font-size: 12px;
					letter-spacing: 0;
					padding: 3px 15px 3px 15px;
					line-height: 16px;

					a{
						font-family: $whitneySemiboldList !important;
					}

					&.grey{
						background: #F2F3F2;
					}

					&.center-cell{
						border-right: 1px solid $border-table;
						border-left: 1px solid $border-table;
					}

					&:first-child{

						a{
							text-decoration: underline;
						}
						text-align: left;
					}

					.red{
						color: $red;
					}
				
					.green{
						color: $green;
					}
				}

				.borders {
					border-right: 1px solid $border-table;
				}

				.fundamental-cell {
					margin-right: 10px;
				}
			}
		}

		tfoot{
			td{
				padding: 5px 15px 5px 15px;
			}
		}
	}
}