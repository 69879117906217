@import "assets/styles/variables.scss";

.trade-link{
	color: $dark;
	font-family: $whitneySemiboldList;
	text-align: left;
	padding: 0px;
	margin: 0px;
	line-height: 0px;
	border: none;
	background: transparent;
}